## 公共服务

- [乳齿象实例 mstdn.soine.site](https://mstdn.soine.site)
- [www.yude.jp.eu.org](https://www.yude.jp.eu.org)
- soine.site 共享服务
     - [soine.site 首页](https://soine.site)
- Minecraft 多人游戏服务器
     - 服务器地址: `yude.jp`
- TeamSpeak 3 服务器
     - 服务器地址: `yude.jp`
- Internet Relay Chat
    - 服务器地址: `irc.yude.jp`
- OpenTTD 多人游戏服务器
    - 服务器地址: `openttd.yude.jp`

## 关联

- [GitHub 组织](https://github.com/yudejp)
- [Discord 服务器](https://github.com/yudejp)
- [服务运行状态/故障历史](https://status.yude.jp)

## 连接性

- The Onion Router
    - `yudejpwxp2cziclocqjfd55ucw2dh6ncswopluh7exwusjlfkvkwhwqd.onion`
- I2P
    - `idabfrazqbh7upvo2f5hx3ajpqglrwny66qbvcoatfqoq64ifiaq.b32.i2p`
- Gemini
    - `gemini://gemini.yude.jp`
